import axios from 'utils/axios'
import useClientCase from './useClientCase'

const useIntakeForm = () => {
  const { clientCaseCustomerOneTimeUpload } = useClientCase()
  const dateToString = (date) => {
    try {
      return date.toISOString()
    } catch (err) {
      return ''
    }
  }

  const submitForm = async (formValues, setStatus) => {
    try {
      const res = await axios.post('/customer/intake', {
        customer: {
          customer_email: formValues.customer_email,
          customer_phone: formValues.customer_phone,
          customer_first_name: formValues.customer_first_name,
          customer_last_name: formValues.customer_last_name,
          customer_insurance_company: formValues.customer_insurance_company,
          customer_address_line1: formValues.customer_address_line1,
          customer_address_line2: formValues.customer_address_line2,
          customer_address_city: formValues.customer_address_city,
          customer_address_state: formValues.customer_address_state?.value,
          customer_address_zip: formValues.customer_address_zip,
          customer_timezone: formValues.customer_timezone,
          customer_subscriber_relation: formValues.customer_subscriber_relation,
          customer_best_call_time: formValues.customer_best_call_time,
        },
        client_case: {
          client_case_timing: formValues.client_case_timing,
          client_case_denied_datetime: dateToString(
            formValues.client_case_denied_datetime
          ),
          client_case_service_datetime: formValues.client_case_service_datetime
            ? dateToString(formValues.client_case_service_datetime)
            : null,
          client_case_due_date: dateToString(formValues.client_case_due_date),
          client_case_appeal_type: formValues.client_case_appeal_type,
          client_case_service_level: formValues.client_case_service_level,
          client_case_customer_signature:
            formValues.client_case_customer_signature,
        },
        has_files:
          Array.isArray(formValues.files) && formValues.files.length > 0,
      })

      console.log(res)
      // LOG INTO THING
      if (res.status === 200) {
        // handle file upload
        let thankYouError = null
        if (
          res.data?.fileuploadtoken &&
          Array.isArray(formValues.files) &&
          formValues.files.length > 0
        ) {
          if (
            !(await clientCaseCustomerOneTimeUpload(
              res.data.fileuploadtoken,
              res.data.client_case.client_case_id,
              formValues.files
            ))
          ) {
            thankYouError =
              'The documents you uploaded have failed to upload. You will have to re-upload them via your personal case portal.'
          }
        }

        if (thankYouError) {
          alert(thankYouError)
        }
      }
      return true
    } catch (err) {
      console.log(err)
      setStatus({
        errors: { submit: err.response?.data ?? 'An error has occurred' },
      })
      return false
    }
  }

  return {
    submitForm,
  }
}

export default useIntakeForm
