import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormikContext } from 'formik'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import UploadFileIcon from '@mui/icons-material/UploadFile'
import CloseIcon from '@mui/icons-material/Close'

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  marginTop: 4,
}

const thumbInner = {
  minWidth: 0,
  overflow: 'hidden',
  height: '100px',
  width: 'auto',
  position: 'relative',
}

const Thumbnail = ({ file, index, fieldName }) => {
  const { values, handleChange } = useFormikContext()
  const [filename, setFilename] = useState(file.name)
  const [originalFilename] = useState(file.name)

  const handleDelete = () => {
    const files = [...values[fieldName]]
    files.splice(index, 1)
    handleChange({ target: { name: fieldName, value: files } })
  }

  const handleRename = (e) => {
    setFilename(e.target.value)
  }

  const handleBlur = () => {
    const files = [...values[fieldName]]
    files[index] = { ...files[index], filename }
    handleChange({ target: { name: fieldName, value: files } })
  }

  return (
    <Box key={`${file.name}-${index}`}>
      <Grid
        container
        spacing={4}
        sx={{
          ...thumbInner,
        }}
      >
        <Grid item xs={1} sx={{ display: 'flex' }}>
          <IconButton onClick={handleDelete} sx={{ my: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          item
          xs={2}
        >
          <img
            src={file.preview}
            style={{
              maxHeight: '75px',
              height: 'auto',
              width: 'auto',
              maxWidth: '70px',
              overflow: 'hidden',
            }}
            alt=""
          />
        </Grid>
        <Grid item xs={9} sx={{ display: 'flex' }}>
          <TextField
            fullWidth
            sx={{ my: 'auto' }}
            label={`Filename: (Original ${originalFilename})`}
            defaultValue={file.name}
            onChange={handleRename}
            onBlur={handleBlur}
            value={filename}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const Dropzone = ({ fieldData }) => {
  const { values, handleChange } = useFormikContext()

  const files = values[fieldData.field] || []

  const setFiles = (newFiles) => {
    handleChange({
      target: {
        name: fieldData.field,
        value: newFiles,
      },
    })
  }

  const getFilePreview = (file) => {
    switch (file.type) {
      case 'image/png':
        return URL.createObjectURL(file)
      case 'application/pdf':
        return `/static/imgs/pdf.png`
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      case 'application/vnd.ms-excel':
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
      case 'text/csv':
      case 'application/csv':
        return `/static/imgs/excel.png`
      case 'application/msword':
      case 'application/vnd.ms-word.document.macroEnabled.12':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      case 'application/vnd.ms-word.template.macroEnabled.12':
        return `/static/imgs/word.png`
      default:
        return `/static/imgs/file.png`
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      '.pdf, .png, .jpg, .tiff, .txt, .docx, .docm, .doc, .dot, .dotm, .csv, .xlsx, .xls',
    onDrop: (acceptedFiles) => {
      setFiles(
        files.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: getFilePreview(file),
              filename: file.name,
            })
          )
        )
      )
    },
  })

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  return (
    <Container>
      {typeof fieldData.display === 'string' ? (
        <Typography>{fieldData.display}</Typography>
      ) : (
        fieldData.display
      )}
      <Box
        component="div"
        {...getRootProps({
          sx: {
            height: '75px',
            border: '1px dashed',
            borderColor: 'primary.main',
            cursor: 'pointer',
          },
        })}
      >
        <Box component="input" {...getInputProps()} sx={{ height: '100%' }} />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            height: '100%',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              width: '80%',
              m: 'auto',
              justifyItems: 'center',
            }}
          >
            <UploadFileIcon />
            <Typography>
              Upload files here (.pdf, .png, .jpg, .tiff, .txt, .docx, .docm,
              .doc, .dot, .dotm, .csv, .xlsx, .xls)
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="max-w-5xl" sx={thumbsContainer}>
        <Stack spacing={2}>
          {files.map((file, index) => (
            <Thumbnail
              key={index}
              file={file}
              index={index}
              fieldName={fieldData.field}
            />
          ))}
        </Stack>
      </Box>
    </Container>
  )
}

export default Dropzone
