import React, { useRef, useState } from 'react'
import { Formik } from 'formik'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'

import Stack from '@mui/material/Stack'

import FieldGroup from 'components/common/FormRenderer/FieldGroup'

import useUser from 'hooks/useUser'
import resolveErrorText from 'helpers/resolveErrorText'

const initialValues = {
  user_id: null,
  user_first_name: '',
  user_last_name: '',
  user_email: '',
  user_phone: '',
  user_type: '',
}

function UserCreateEditForm({ user, submitSuccessCallback }) {
  const {
    user_id,
    user_first_name,
    user_last_name,
    user_email,
    user_phone,
    user_type,
  } = user || initialValues

  const submitCallback = submitSuccessCallback || null

  const defaultValues = {
    user_first_name,
    user_last_name,
    user_email,
    user_phone,
    user_type,
  }
  const formikRef = useRef(null)
  const { userUpdate, userCreate } = useUser()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values, { setErrors }) => {
    try {
      setLoading(true)
      let payload = {
        user_first_name: values.user_first_name,
        user_last_name: values.user_last_name,
        user_phone: values.user_phone,
        user_type: values.user_type,
      }
      // if user_id is supplied, it's user update, otherwise, user create
      if (user_id) {
        payload.user_id = user_id
        await userUpdate(payload)
      } else {
        payload.user_email = values.user_email
        await userCreate(payload)
      }

      if (submitCallback) {
        submitCallback()
      }
      if (!user_id) {
        // reset create form - clear out
        formikRef.current.resetForm()
      }
    } catch (err) {
      setErrors({ submit: resolveErrorText(err) })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validateOnChange={false}
      p={2}
    >
      <Stack p={1}>
        {formikRef?.current?.errors?.submit && (
          <Alert severity="warning">{formikRef?.current?.errors?.submit}</Alert>
        )}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6}>
            <FieldGroup
              fieldData={{
                field: 'user_first_name',
                display: 'First Name',
                type: 'text',
                InputProps: {
                  disabled: loading,
                  required: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldGroup
              fieldData={{
                field: 'user_last_name',
                display: 'Last Name',
                type: 'text',
                InputProps: {
                  disabled: loading,
                  required: true,
                },
              }}
            />
          </Grid>
          {user_id ? null : (
            <Grid item xs={12}>
              <FieldGroup
                fieldData={{
                  field: 'user_email',
                  display: 'Email',
                  type: 'email',
                  InputProps: {
                    disabled: loading,
                    required: true,
                  },
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FieldGroup
              fieldData={{
                field: 'user_phone',
                type: 'phone',
                display: 'Phone',
                InputProps: {
                  disabled: loading,
                  required: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldGroup
              fieldData={{
                field: 'user_type',
                display: 'Type',
                type: 'select',
                fieldType: 'select',
                options: [
                  { value: 'Staff', display: 'Staff' },
                  { value: 'Consultant', display: 'Consultant' },
                ],
                InputProps: {
                  disabled: loading,
                  required: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={loading}
              fullWidth={true}
              color="primary"
              variant="contained"
              onClick={() => formikRef?.current?.submitForm()}
            >
              {user_id ? 'Update' : 'Create'}&nbsp;User
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Formik>
  )
}

export default UserCreateEditForm
