import React, { useReducer, createContext, useEffect } from 'react'

import axios from 'utils/axios'

import isValidToken from 'helpers/auth/isValidToken'

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  token: {
    accessToken: '',
    accessTokenExpires: '',
    refreshToken: '',
    refreshTokenExpires: '',
  },
  challenge: '',
}

const storeState = (state) => {
  // exclude defined vars from local storage
  const { isInitialized, isAuthenticated, payload, ...rest } = state ?? {}

  if (isValidToken(state?.token ?? {}))
    localStorage.customerState = JSON.stringify(rest)
  else delete localStorage.customerState
}

const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        isInitialized: true,
        token: action.payload.token || {},
        challenge: action.payload.challenge,
      }
    case 'LOGIN':
      return {
        ...state,
        isInitialized: true,
        token: action.payload.token,
        challenge: action.payload?.challenge,
      }
    case 'LOGOUT': {
      delete localStorage.customerState
      delete axios.defaults.headers.Authorization
      return {
        ...initialState,
        isInitialized: true,
      }
    }
    case 'UPDATE_TOKEN': {
      const {
        accessToken,
        accessTokenExpires,
        refreshToken,
        refreshTokenExpires,
      } = action.payload

      let coupledUpdate = {
        ...state.token,
        accessToken,
        accessTokenExpires,
      }

      if (refreshToken) {
        coupledUpdate.refreshToken = refreshToken
      }
      if (refreshTokenExpires) {
        coupledUpdate.refreshTokenExpires = refreshTokenExpires
      }

      // set the authorization header for all future requests
      // delete user state if exists
      if (localStorage.userState) {
        delete localStorage.userState
      }

      axios.defaults.headers.Authorization = `Bearer ${accessToken}`

      return {
        ...state,
        token: {
          ...state.token,
          ...coupledUpdate,
        },
      }
    }
    case 'UPDATE_CHALLENGE': {
      const { challenge, ...props } = action.payload

      return {
        ...state,
        ...props,
        challenge,
      }
    }
    case 'SET_AUTHENTICATION': {
      const { isAuthenticated } = action.payload

      return {
        ...state,
        isAuthenticated,
      }
    }
    case 'STORE_STATE': {
      storeState(state)
      return state
    }
    default:
      return state
  }
}

const CustomerAuthContext = createContext(null)

function CustomerAuthProvider({ children }) {
  const [state, dispatch] = useReducer(AuthReducer, initialState)

  const updateToken = ({
    token,
    token_expires,
    refresh_token,
    refresh_token_expires,
  }) => {
    dispatch({
      type: 'UPDATE_TOKEN',
      payload: {
        accessToken: token,
        accessTokenExpires: token_expires,
        refreshToken: refresh_token,
        refreshTokenExpires: refresh_token_expires,
      },
    })
  }

  const refreshTokens = async () => {
    try {
      if (state?.isInitialized && state?.token?.refreshToken) {
        const res = await axios.post(
          '/auth/customer/refresh',
          {
            refreshToken: state.token.refreshToken,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token.refreshToken}`,
            },
          }
        )

        if (res.status === 200) {
          const refreshData = res.data

          updateToken(refreshData)

          return refreshData
        }

        throw new Error('Failed to refresh token')
      }
    } catch (err) {
      return null
    }
    return null
  }

  // just send the TOTP based off of email
  const customerSendTOTP = async ({ email }) => {
    if (
      isValidToken(state?.token) ||
      (localStorage.userState && isValidToken(localStorage.userState))
    ) {
      throw new Error('Cannot send TOTP if already logged in')
    }
    let res = await axios.post(
      '/auth/customer/sendTOTP',
      {
        email: email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return res.status === 204
  }

  const login = async ({ email, totp }) => {
    // axios amends base API endpoint by default
    // see utils/axios for default settings
    const res = await axios.post(
      `/auth/customer/login`,
      {
        email,
        totp,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    const loginData = res.data
    const accessToken = loginData.token

    // store user data in global state
    dispatch({
      type: 'INITIALIZE',
      payload: {
        token: {
          email,
          accessToken,
          accessTokenExpires: loginData.token_expires,
        },
        challenge: loginData.challenge,
      },
    })

    updateToken(loginData)

    return loginData
  }

  const logout = async () => {
    // do not cancel the logout process
    try {
      axios.post(
        `/auth/customer/logout`,
        {
          refreshToken: state.token.refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${state.token.refreshToken}`,
          },
        }
      )
    } catch (err) {}

    dispatch({
      type: 'LOGOUT',
    })
  }

  const initialize = () => {
    try {
      const customerState = JSON.parse(
        localStorage.customerState ?? JSON.stringify(initialState)
      )

      if (customerState.challenge) {
        // sign out user if they refresh when there's a challenge
        throw new Error('Challenge detected')
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            ...customerState,
          },
        })

        if (
          customerState?.token?.accessToken ||
          customerState?.token?.accessTokenExpires
        ) {
          updateToken({
            token: customerState.token.accessToken,
            token_expires: customerState.token.accessTokenExpires,
          })
        }
      }
    } catch (err) {
      storeState(initialState)
    }
  }

  const isCustomerAuthenticated = (tokenData) => {
    if (!tokenData) return false
    return isValidToken(tokenData) && !state.challenge
  }

  const setAuthentication = (isAuthenticated) => {
    dispatch({
      type: 'SET_AUTHENTICATION',
      payload: {
        isAuthenticated,
      },
    })
  }

  useEffect(initialize, [])

  // store state in local storage whenever state changes in memory
  useEffect(() => {
    const asyncCallback = async () => {
      if (state.isInitialized) {
        let tokenData = state.token

        if (!isValidToken(state.token)) {
          tokenData = await refreshTokens()
        }

        const isAuthenticated = isCustomerAuthenticated(tokenData)

        if (state.isAuthenticated !== isAuthenticated)
          setAuthentication(isAuthenticated)

        dispatch({ type: 'STORE_STATE' })
      }
    }

    asyncCallback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <CustomerAuthContext.Provider
      value={{
        ...state,
        customerSendTOTP,
        updateToken,
        login,
        logout,
      }}
    >
      {children}
    </CustomerAuthContext.Provider>
  )
}

export { CustomerAuthContext, CustomerAuthProvider }
