import React, { useEffect, useState } from 'react'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useParams } from 'react-router-dom'
import DataTable from 'components/common/DataTable'

import SingleTitleLayout from 'layouts/SingleTitle'
import useDIY from 'hooks/useDIY'

function DIYLetterDownload() {
  const { token } = useParams()
  const { diyData, documentDownload } = useDIY()
  const [diyDocs, setDIYDocs] = useState(null)
  const [loadedData, setLoadedData] = useState(false)
  useEffect(() => {
    diyData(token)
      .then((resp) => {
        let displayDocuments = [
          // {
          //   'File Name': 'Proof Of Concept',
          //   'File Type': 'PDF',
          //   'File Size (bytes)': 1234567,
          //   Download: (
          //     <Button
          //       variant="contained"
          //       onClick={() => {
          //         documentDownload(7, resp.token)
          //       }}
          //     >
          //       Download
          //     </Button>
          //   ),
          // },
        ]
        if (Array.isArray(resp.documents) && resp.documents.length > 0) {
          for (let i in resp.documents) {
            displayDocuments.push({
              'File Name': resp.documents[i].document_file_name,
              'File Type': resp.documents[i].document_file_type,
              'File Size (bytes)': resp.documents[i].document_file_size,
              Download: (
                <Button
                  variant="contained"
                  onClick={() => {
                    documentDownload(resp.documents[i].document_id, resp.token)
                  }}
                >
                  Download
                </Button>
              ),
            })
          }
        }
        setDIYDocs(displayDocuments)
        setLoadedData(true)
      })
      .catch(() => {
        // just kick back to another page - nothing is here.
        window.location.href = '/'
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SingleTitleLayout title="Do It Yourself Documents">
      {loadedData && (
        <>
          <Typography component="h6">
            Download all documents you may need. Note that leaving this page
            will cause you to lose access, so download them all NOW
          </Typography>
          <Grid container spacing={3} p={1} mb={5}>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <DataTable tableRows={diyDocs || []} />
              {!Array.isArray(diyDocs) || diyDocs.length === 0 ? (
                <Typography component="i" variant="h3" p={1}>
                  No documents are currently available. You should contact
                  support immediately.
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </>
      )}
    </SingleTitleLayout>
  )
}

export default DIYLetterDownload
