import axios from 'utils/axios'

const useCustomer = () => {
  const customerSearch = async (searchTerms) => {
    try {
      const { data } = await axios.get(`/customer/search`, {
        params: searchTerms,
      })
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const customerUpdate = async (payload) => {
    const { data } = await axios.post(`/customer/update`, payload)
    return data
  }

  return {
    customerSearch,
    customerUpdate,
  }
}

export default useCustomer
