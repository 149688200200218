import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import DataTable from 'components/common/DataTable'

import useClientCase from 'hooks/useClientCase'

import { format, parseISO } from 'date-fns'

function CaseBrowser() {
  const [loading, setLoading] = useState(false)
  const { clientCaseSearch } = useClientCase()
  const [clientCaseSearchParams, setClientCaseSearchParams] = useState(null)
  const [clientCases, setClientCases] = useState([])
  const [clientCaseDisplay, setClientCaseDisplay] = useState([])
  const [hasMoreCases, setHasMoreCases] = useState(false)

  const searchLookup = async (payload, appendResults, setErrors) => {
    try {
      let res = await clientCaseSearch(payload)
      if (res?.client_cases && res.client_cases.length > 0) {
        if (appendResults) {
          setClientCases([...clientCases, ...res.client_cases])
        } else {
          setClientCases(res.client_cases)
        }
      } else if (!appendResults) {
        setClientCases([])
      }

      setHasMoreCases(
        res?.client_cases?.length > 0 &&
          res.client_cases.length === (res?.limit || 0)
      )
      payload.offset = res?.offset || 0
      payload.limit = res?.limit || null
      setClientCaseSearchParams(payload)
      return true
    } catch (err) {
      if (setErrors) {
        setErrors({
          submit: err.response?.data?.message ?? 'An error has occurred',
        })
      }
      return false
    }
  }

  useEffect(() => {
    if (Array.isArray(clientCases) && clientCases.length > 0) {
      let newDisplay = []
      for (let i = 0; i < clientCases.length; i++) {
        newDisplay.push({
          Assignee: clientCases[i].user_id ? (
            <Typography>
              {clientCases[i].user.user_first_name}{' '}
              {clientCases[i].user.user_last_name}
            </Typography>
          ) : (
            'Unassigned'
          ),
          Timing: clientCases[i].client_case_timing,
          'Service Date': clientCases[i]?.client_case_service_datetime
            ? format(
                parseISO(clientCases[i].client_case_service_datetime),
                'M/d/y'
              )
            : 'N/A',
          'Denial Date': clientCases[i]?.client_case_denied_datetime
            ? format(
                parseISO(clientCases[i].client_case_denied_datetime),
                'M/d/y'
              )
            : 'N/A',
          'Due Date': clientCases[i]?.client_case_due_date
            ? format(parseISO(clientCases[i].client_case_due_date), 'M/d/y')
            : 'N/A',
          'Service Level': clientCases[i].client_case_service_level,
          Status: clientCases[i].client_case_status,
          Determination: clientCases[i].client_case_determination,
          Created: format(
            parseISO(clientCases[i].client_case_created_datetime),
            'M/d/y h:mm a'
          ),
          Actions: (
            <Link to={`/portal/case-detail/${clientCases[i].client_case_id}`}>
              <Button variant="contained">Manage</Button>
            </Link>
          ),
        })
      }
      setClientCaseDisplay(newDisplay)
    } else {
      setClientCaseDisplay([])
    }
  }, [clientCases])

  useEffect(() => {
    ;(async function fetchInitialData() {
      setLoading(true)
      try {
        await searchLookup(clientCaseSearchParams || {}, false, undefined)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getNextPage = async () => {
    if (hasMoreCases) {
      setLoading(true)
      try {
        let payload = {
          ...clientCaseSearchParams,
        }
        payload.offset = (payload.limit || 0) + (payload.offset || 0)
        await searchLookup(payload, true, undefined)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading && (
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <DataTable
                title="Cases"
                tableHeadStyling={{
                  whiteSpace: 'nowrap',
                }}
                tableRows={clientCaseDisplay || []}
                seeMoreState={hasMoreCases}
                seeMoreFunction={getNextPage}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default CaseBrowser
