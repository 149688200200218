import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import DataTable from 'components/common/DataTable'

import useDIY from 'hooks/useDIY'
import { Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'

const defaultDIYParams = {
  sort: {
    diy_intake_created_datetime: false,
  },
  limit: 15,
  offset: 0,
}

function DIYIntakeLog() {
  const { authDIYSearch } = useDIY()
  const [diyLogs, setDIYLogs] = useState([])
  const [diySearchParams, setDIYSearchParams] = useState(defaultDIYParams)
  const [diyHasMore, setDIYHasMore] = useState(true)

  const addNewIntakeLogs = (new_logs) => {
    let diyDisplay = []
    if (Array.isArray(new_logs) && new_logs.length > 0) {
      for (let i in new_logs) {
        diyDisplay.push({
          Name: new_logs[i].diy_intake_customer_name,
          Email: new_logs[i].diy_intake_customer_email,
          Paid: new_logs[i].diy_intake_payment_confirmed ? 'Yes' : 'No',
          'Link Accessed': new_logs[i].diy_intake_download_accessed
            ? 'Yes'
            : 'No',
          'Payment Token': new_logs[i].diy_intake_stripe_payment_intent,
          Created: format(
            parseISO(new_logs[i].diy_intake_created_datetime),
            'M/d/y h:mm a'
          ),
        })
      }
    }
    setDIYLogs([...diyLogs, ...diyDisplay])
  }

  const loadLogs = () => {
    if (diyHasMore) {
      authDIYSearch(diySearchParams).then((resp) => {
        addNewIntakeLogs(resp.diy_intakes)
        setDIYHasMore(
          diySearchParams.limit === (resp?.diy_intakes?.length || 0)
        )
        setDIYSearchParams({
          ...diySearchParams,
          offset: diySearchParams.offset + (resp?.diy_intakes?.length || 0),
        })
      })
    }
  }

  useEffect(() => {
    loadLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          {diyLogs.length > 0 ? (
            <DataTable
              title="DIY Intake Logs"
              tableRows={diyLogs}
              seeMoreFunc={loadLogs}
              seeMoreState={diyHasMore}
            />
          ) : (
            <Typography component="i">
              Nobody has submitted a "Do It Yourself" request
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DIYIntakeLog
