import React from 'react'
import { useSearchParams } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import SingleTitleLayout from 'layouts/SingleTitle'
function DIYThanks() {
  const [searchParams] = useSearchParams()

  return (
    <SingleTitleLayout title="Thank You!">
      <Stack spacing={2}>
        Thank you <strong>{searchParams.get('name')}</strong> for your request.
        An email will be sent to <strong>{searchParams.get('email')}</strong>{' '}
        with further instructions.
      </Stack>
    </SingleTitleLayout>
  )
}

export default DIYThanks
