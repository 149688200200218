import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import DataTable from 'components/common/DataTable'
import useAuth from 'hooks/context/useAuth'
import useClientCase from 'hooks/useClientCase'
import resolveErrorText from 'helpers/resolveErrorText'
import { format, addDays, parseISO } from 'date-fns'

function Content() {
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState('upcoming')
  const [soonCases, setSoonCases] = useState([])
  const [soonCaseParams, setSoonCaseParams] = useState(null)
  const [hasMoreSoonCases, setHasMoreSoonCases] = useState(false)
  const [soonCaseDisplay, setSoonCaseDisplay] = useState([])

  const [clientCases, setClientCases] = useState([])
  const [clientCaseParams, setClientCaseParams] = useState(null)
  const [hasMoreClientCases, setHasMoreClientCases] = useState(false)
  const [clientCaseDisplay, setClientCaseDisplay] = useState([])

  const { clientCaseSearch } = useClientCase()
  const nowDate = addDays(new Date(), 7).toISOString()

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue)
  }

  const sortSoonCases = (caseA, caseB) => {
    if (
      !caseA?.client_case_due_date &&
      !caseB?.client_case?.client_case_due_date
    ) {
      return 0
    } else if (!caseA?.client_case_due_date) {
      return 1
    } else if (!caseB?.client_case_due_date) {
      return -1
    }

    // confirmed both have values
    if (caseA.convertedDueDatetime === undefined) {
      caseA.convertedDueDatetime = parseISO(caseA.client_case_due_date)
    }
    if (caseB.convertedDueDatetime === undefined) {
      caseB.convertedDueDatetime = parseISO(caseB.client_case_due_date)
    }
    if (caseA.convertedDueDatetime > caseB.convertedDueDatetime) {
      return 1
    } else if (caseB.convertedDueDatetime > caseA.convertedDueDatetime) {
      return -1
    }
    // if dates identical, return based on small id = sooner.
    return caseA.client_case_id - caseB.client_case_id
  }

  // run this once - just for the initial data
  useEffect(
    () => {
      if (user?.id) {
        ;(async function fetchData() {
          setLoading(true)
          try {
            if (tabValue === 'upcoming') {
              let soonParams = {
                user_id: user.id,
                client_case_due_date_before: nowDate,
                sort: {
                  client_case_due_date: true,
                },
                limit: 10,
                offset: 0,
              }
              await searchLookup(
                soonParams,
                [],
                setSoonCases,
                setHasMoreSoonCases,
                setSoonCaseParams,
                sortSoonCases,
                undefined
              )
            } else {
              let caseParams = {
                user_id: user.id,
                limit: 10,
                offset: 0,
                sort: {
                  client_case_id: true,
                },
              }
              await searchLookup(
                caseParams,
                [],
                setClientCases,
                setHasMoreClientCases,
                setClientCaseParams,
                undefined,
                undefined
              )
            }
          } catch (err) {
            console.log(err)
          } finally {
            setLoading(false)
          }
        })()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, tabValue]
  )

  const searchLookup = async (
    payload,
    currentState,
    setStateFunc,
    setHasMoreFunc,
    setCurrentPaginationParams,
    newSortFunc,
    setErrors
  ) => {
    try {
      let res = await clientCaseSearch(payload)
      let newState = []
      if (Array.isArray(res?.client_cases) && res.client_cases.length > 0) {
        if (currentState) {
          newState = [...currentState, ...res.client_cases]
        } else {
          newState = res.client_cases
        }
        setHasMoreFunc(res.client_cases.length === (res?.limit || -1))
      } else if (!currentState) {
        setHasMoreFunc(false)
      }
      payload.offset = res?.offset || 0
      payload.limit = res?.limit || null
      setCurrentPaginationParams(payload)
      if (newState && newSortFunc) {
        newState.sort(newSortFunc)
      }
      setStateFunc(newState)
      return true
    } catch (e) {
      if (setErrors) {
        setErrors(resolveErrorText(e))
      }
      return false
    }
  }

  useEffect(() => {
    let newDisplay = []
    if (Array.isArray(soonCases) && soonCases.length > 0) {
      for (let i = 0; i < soonCases.length; i++) {
        newDisplay.push({
          ID: soonCases[i].client_case_id,
          Customer: `${soonCases[i].customer.customer_first_name} ${soonCases[i].customer.customer_last_name}`,
          Timing: soonCases[i].client_case_timing,
          Status: soonCases[i].client_case_status,
          'Service Date': soonCases[i]?.client_case_service_datetime
            ? format(
                parseISO(soonCases[i].client_case_service_datetime),
                'M/d/y'
              )
            : 'N/A',
          'Denial Date': soonCases[i]?.client_case_denied_datetime
            ? format(
                parseISO(soonCases[i].client_case_denied_datetime),
                'M/d/y'
              )
            : 'N/A',
          'Due Date': soonCases[i]?.client_case_due_date
            ? format(parseISO(soonCases[i].client_case_due_date), 'M/d/y')
            : 'N/A',
          Created: format(
            parseISO(soonCases[i].client_case_created_datetime),
            'M/d/y h:mm a'
          ),
          Actions: (
            <Link to={`/console/case-detail/${soonCases[i].client_case_id}`}>
              <Button variant="contained">Manage</Button>
            </Link>
          ),
        })
      }
    }
    setSoonCaseDisplay(newDisplay)
  }, [soonCases])

  useEffect(() => {
    let newDisplay = []
    if (Array.isArray(clientCases) && clientCases.length > 0) {
      for (let i = 0; i < clientCases.length; i++) {
        newDisplay.push({
          ID: clientCases[i].client_case_id,
          Customer: `${clientCases[i].customer.customer_first_name} ${clientCases[i].customer.customer_last_name}`,
          Timing: clientCases[i].client_case_timing,
          Status: clientCases[i].client_case_status,
          'Service Date': clientCases[i]?.client_case_service_datetime
            ? format(
                parseISO(clientCases[i].client_case_service_datetime),
                'M/d/y'
              )
            : 'N/A',
          'Denial Date': clientCases[i]?.client_case_denied_datetime
            ? format(
                parseISO(clientCases[i].client_case_denied_datetime),
                'M/d/y'
              )
            : 'N/A',
          'Due Date': clientCases[i]?.client_case_due_date
            ? format(parseISO(clientCases[i].client_case_due_date), 'M/d/y')
            : 'N/A',
          Created: format(
            parseISO(clientCases[i].client_case_created_datetime),
            'M/d/y h:mm a'
          ),
          Actions: (
            <Link to={`/console/case-detail/${clientCases[i].client_case_id}`}>
              <Button variant="contained">Manage</Button>
            </Link>
          ),
        })
      }
    }
    setClientCaseDisplay(newDisplay)
  }, [clientCases])

  const getNextPage = async (
    hasMoreState,
    currentState,
    setStateFunc,
    setHasMoreFunc,
    currentPaginationParams,
    setCurrentPaginationParams,
    newSortFunc
  ) => {
    if (hasMoreState) {
      setLoading(true)
      try {
        let payload = {
          ...currentPaginationParams,
        }
        payload.offset = (payload.limit || 0) + (payload.offset || 0)
        await searchLookup(
          payload,
          currentState,
          setStateFunc,
          setHasMoreFunc,
          setCurrentPaginationParams,
          newSortFunc,
          undefined
        )
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="case tabs">
            <Tab label="Upcoming / Overdue Cases" value="upcoming" />
            <Tab label="All Assigned Cases" value="all" />
          </TabList>
        </Box>

        <TabPanel value="upcoming" index={0}>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <Typography component="h2" variant="h2" mb={2} textAlign="center">
                <strong>Upcoming / Overdue Cases</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {Array.isArray(soonCaseDisplay) && soonCaseDisplay.length > 0 ? (
                <DataTable
                  title=""
                  tableHeadStyling={{
                    ID: {
                      textAlign: 'left',
                    },
                    Customer: {
                      textAlign: 'left',
                    },
                    Timing: {
                      textAlign: 'left',
                    },
                    Status: {
                      textAlign: 'left',
                    },
                    core: {
                      textAlign: 'right',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  tableCellStyling={{
                    ID: {
                      textAlign: 'left',
                    },
                    Customer: {
                      textAlign: 'left',
                    },
                    Timing: {
                      textAlign: 'left',
                    },
                    Status: {
                      textAlign: 'left',
                    },
                    core: {
                      textAlign: 'right',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  tableRows={soonCaseDisplay || []}
                  seeMoreState={hasMoreSoonCases && !loading}
                  seeMoreFunction={() => {
                    getNextPage(
                      hasMoreSoonCases,
                      soonCases,
                      setSoonCases,
                      setHasMoreSoonCases,
                      soonCaseParams,
                      setSoonCaseParams,
                      sortSoonCases,
                      undefined
                    )
                  }}
                />
              ) : (
                <Typography
                  component="h3"
                  variant="h3"
                  p={1}
                  textAlign="center"
                >
                  <i>No cases are overdue or coming due within the week</i>
                </Typography>
              )}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="all" index={1}>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <Typography component="h2" variant="h2" mb={2} textAlign="center">
                <strong>All Cases</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {Array.isArray(soonCaseDisplay) && soonCaseDisplay.length > 0 ? (
                <DataTable
                  title=""
                  tableHeadStyling={{
                    ID: {
                      textAlign: 'left',
                    },
                    Customer: {
                      textAlign: 'left',
                    },
                    Timing: {
                      textAlign: 'left',
                    },
                    Status: {
                      textAlign: 'left',
                    },
                    core: {
                      textAlign: 'right',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  tableCellStyling={{
                    ID: {
                      textAlign: 'left',
                    },
                    Customer: {
                      textAlign: 'left',
                    },
                    Timing: {
                      textAlign: 'left',
                    },
                    Status: {
                      textAlign: 'left',
                    },
                    core: {
                      textAlign: 'right',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  tableRows={clientCaseDisplay || []}
                  seeMoreState={hasMoreClientCases && !loading}
                  seeMoreFunction={() => {
                    getNextPage(
                      hasMoreClientCases,
                      clientCases,
                      setClientCases,
                      setHasMoreClientCases,
                      clientCaseParams,
                      setClientCaseParams,
                      undefined,
                      undefined
                    )
                  }}
                />
              ) : (
                <Typography
                  component="h3"
                  variant="h3"
                  p={1}
                  textAlign="center"
                >
                  <i>No cases have been assigned to you</i>
                </Typography>
              )}
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </Paper>
  )
}

export default Content
