import React, {
  useReducer,
  createContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'

import axios from 'utils/axios'

import isValidToken from 'helpers/auth/isValidToken'

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: {
    id: null,
    isAdmin: false,
    type: false,
    name: '',
  },
  token: {
    accessToken: '',
    accessTokenExpires: '',
    refreshToken: '',
    refreshTokenExpires: '',
  },
  tokenInterceptorID: undefined,
  challenge: '',
  payload: '',
}

const storeState = (state) => {
  // exclude defined vars from local storage
  const { isInitialized, isAuthenticated, payload, ...rest } = state ?? {}
  if (!isValidToken(state?.token)) {
    delete localStorage.userState
  } else {
    localStorage.userState = JSON.stringify({
      ...rest,
    })
  }
}

const useAuthState = () => {
  const livingState = useRef(initialState)
  const AuthReducer = (state, action) => {
    let result = {}
    switch (action.type) {
      case 'INITIALIZE':
        result = {
          ...state,
          isInitialized: true,
          isAuthenticated: action.payload.isAuthenticated || false,
          user: action.payload.user,
          token: action.payload.token,
          tokenInterceptorID:
            action.payload.tokenIncerceptorID ||
            state.tokenIncerceptorID ||
            undefined,
          challenge: action.payload.challenge,
          payload: action.payload.payload,
        }
        break
      case 'LOGIN':
        result = {
          ...state,
          isInitialized: true,
          token: action.payload.token,
          challenge: action.payload?.challenge,
          payload: action.payload?.payload,
        }
        break
      case 'LOGOUT': {
        delete localStorage.userState
        delete axios.defaults.headers.Authorization
        if (state.tokenIncerceptorID) {
          axios.interceptors.response.eject(state.tokenIncerceptorID)
        }
        result = {
          ...initialState,
          isInitialized: true,
        }
        break
      }
      case 'UPDATE_TOKEN': {
        const {
          accessToken,
          accessTokenExpires,
          refreshToken,
          refreshTokenExpires,
        } = action.payload

        let coupledUpdate = {
          ...state.token,
          accessToken,
          accessTokenExpires,
        }

        if (refreshToken) {
          coupledUpdate.refreshToken = refreshToken
        }
        if (refreshTokenExpires) {
          coupledUpdate.refreshTokenExpires = refreshTokenExpires
        }

        // set the authorization header for all future requests

        axios.defaults.headers.Authorization = `Bearer ${accessToken}`

        result = {
          ...state,
          token: {
            ...state.token,
            ...coupledUpdate,
          },
        }
        break
      }
      case 'UPDATE_TOKEN_INTERCEPTOR':
        const { tokenInterceptorID } = action.payload
        result = {
          ...state,
          tokenInterceptorID,
        }
        break
      case 'UPDATE_USER':
        const { user } = action.payload
        result = {
          ...state,
          user,
        }
        break
      case 'UPDATE_CHALLENGE': {
        const { challenge, ...props } = action.payload

        result = {
          ...state,
          ...props,
          challenge,
        }
        break
      }
      case 'SET_AUTHENTICATION': {
        const { isAuthenticated } = action.payload

        result = {
          ...state,
          isAuthenticated,
        }
        break
      }
      case 'STORE_STATE': {
        storeState(state)
        result = state
        break
      }
      default:
        result = state
        break
    }
    livingState.current = result
    return result
  }

  return [...useReducer(AuthReducer, initialState), livingState]
}

const AuthContext = createContext(null)

const useRecursiveTimeout = (callback, delay = 1000) => {
  const ref = useRef(null)

  useEffect(() => {
    ref.current = callback
  })

  useEffect(() => {
    const tick = () => {
      const ret = ref.current()
      if (!ret) {
        setTimeout(tick, delay)
      } else if (ret.constructor === Promise) {
        ret.then(() => setTimeout(tick, delay))
      }
    }

    const timer = setTimeout(tick, delay)

    return () => clearTimeout(timer)
  }, [delay])
}

let interceptorQueue = []
let interceptorRefresh = false

function AuthProvider({ children }) {
  const [state, dispatch, livingState] = useAuthState() //useReducer(AuthReducer, initialState)
  const [refreshingTokens, setRefreshingTokens] = useState(false)
  useRecursiveTimeout(() => {
    if (
      state.isInitialized &&
      state.isAuthenticated &&
      state?.token?.refreshTokenExpires
    ) {
      console.log(
        `${new Date().getTime()} - ${state.token.refreshTokenExpires * 1000}`
      )
      if (new Date().getTime() >= state.token.refreshTokenExpires * 1000) {
        dispatch({
          type: 'LOGOUT',
        })
      }
    }
  }, 300000)

  const processInterceptorQueue = useCallback(
    (error, tokenData = null) => {
      interceptorQueue.forEach((prom) => {
        if (error) {
          prom.reject(error)
        } else {
          console.log(tokenData)
          prom.resolve(tokenData)
        }
      })

      if (error) {
        dispatch({
          type: 'LOGOUT',
        })
      }

      interceptorQueue = []
    },
    [dispatch]
  )
  const updateToken = useCallback(
    ({ token, token_expires, refresh_token, refresh_expires }) => {
      dispatch({
        type: 'UPDATE_TOKEN',
        payload: {
          accessToken: token,
          accessTokenExpires: token_expires,
          refreshToken: refresh_token,
          refreshTokenExpires: refresh_expires,
        },
      })
    },
    [dispatch]
  )

  const logout = useCallback(async () => {
    // do not cancel the logout process
    try {
      if (livingState?.current?.token?.refreshToken) {
        axios.post(
          `/auth/logout`,
          {
            refreshToken: livingState.current.token.refreshToken,
          },
          {
            headers: {
              Authorization: `Bearer ${livingState.current.token.refreshToken}`,
            },
          }
        )
      }
    } catch (err) {}

    dispatch({
      type: 'LOGOUT',
    })
  }, [dispatch, livingState])

  const updateUser = useCallback(async () => {
    try {
      const res = await axios.get('/user/get')
      if (res.status === 200) {
        dispatch({
          type: 'UPDATE_USER',
          payload: {
            user: {
              id: res.data.user_id,
              isAdmin: res.data.user_is_admin,
              name: `${res.data.user_first_name} ${res.data.user_last_name}`,
            },
          },
        })
      }
    } catch (err) {
      // fire off logout call.
      await logout()
    }
  }, [logout, dispatch])

  const refreshTokens = useCallback(async () => {
    let refreshToken = livingState?.current?.token?.refreshToken
    if (refreshToken) {
      try {
        const res = await axios.post(
          '/auth/refresh',
          {
            refreshToken: refreshToken,
          },
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        )

        if (res.status === 200) {
          const refreshData = res.data
          await updateToken(refreshData)
          await updateUser()
          let newTokenData = {
            accessToken: refreshData.token,
            accessTokenExpires: refreshData.token_expires,
            refreshToken: refreshData.refresh_token,
            refreshTokenExpires: refreshData.refresh_expires,
          }
          return newTokenData
        }

        throw new Error('Failed to refresh token')
      } catch (err) {
        dispatch({
          type: 'LOGOUT',
        })
        return null
      }
    } else if (livingState.isAuthenticated) {
      throw new Error('Failed to refresh token')
    }
    return null
  }, [updateUser, updateToken, dispatch, livingState])

  const requestRefreshInterceptor = useCallback(
    async (error) => {
      const originalRequest = error?.config

      if (originalRequest && originalRequest.url !== '/auth/refresh') {
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          if (!isValidToken(livingState?.current?.token)) {
            if (interceptorRefresh === (interceptorRefresh = true)) {
              return new Promise(function (resolve, reject) {
                interceptorQueue.push({ resolve, reject })
              })
                .then((newTokenData) => {
                  console.log(newTokenData)
                  originalRequest.headers.Authorization = `Bearer ${newTokenData.accessToken}`
                  return axios(originalRequest)
                })
                .catch((err) => {
                  return Promise.reject(err)
                })
            }
            return new Promise((reject, resolve) => {
              return refreshTokens()
                .then((refreshedTokenData) => {
                  console.log(refreshedTokenData)
                  processInterceptorQueue(null, refreshedTokenData)
                  originalRequest.headers.Authorization = `Bearer ${refreshedTokenData.accessToken}`
                  resolve(axios(originalRequest))
                })
                .catch((err) => {
                  processInterceptorQueue(err, null)
                  reject(err)
                })
                .then(() => {
                  interceptorRefresh = false
                })
            })
          } else if (livingState?.current?.token?.accessToken) {
            originalRequest.headers.Authorization = `Bearer ${livingState.current.token.accessToken}`
            return axios(originalRequest)
          }
          // originalRequest._retry = true
          // let newRequestAuth = null
          // if (!isValidToken(livingState?.current?.token || {})) {
          //   let tokenData = await refreshTokens()
          //   if (tokenData?.accessToken) {
          //     newRequestAuth = `Bearer ${tokenData.accessToken}`
          //   }
          // } else if (livingState?.current?.token?.accessToken) {
          //   newRequestAuth = `Bearer ${livingState.current.token.accessToken}`
          // }
          // if (originalRequest.headers.Authorization !== newRequestAuth) {
          //   originalRequest.headers.Authorization = newRequestAuth
          //   return axios(originalRequest)
          // }
        }
      }
      return Promise.reject(error)
    },
    [refreshTokens, livingState, processInterceptorQueue]
  )

  const login = async ({ email, password }) => {
    // axios amends base API endpoint by default
    // see utils/axios for default settings
    const res = await axios.post(
      `/auth/login`,
      {
        email,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    const loginData = res.data
    const accessToken = loginData.token

    // store user data in global state
    dispatch({
      type: 'INITIALIZE',
      payload: {
        token: {
          accessToken,
          accessTokenExpires: loginData.token_expires,
        },
        challenge: loginData.challenge,
        payload: loginData.payload,
      },
    })

    updateToken(loginData)

    return loginData
  }

  const resolveChallengeResponse = async (apiResponse) => {
    if (apiResponse.status !== 200 || !apiResponse?.data) {
      throw new Error(apiResponse.data)
    }

    updateToken(apiResponse.data)
    if (
      !apiResponse.data?.challenge ||
      apiResponse.data.challenge === 'COMPLETE'
    ) {
      dispatch({
        type: 'UPDATE_CHALLENGE',
        payload: {
          challenge: '',
        },
      })
      await updateUser()
    } else {
      dispatch({
        type: 'UPDATE_CHALLENGE',
        payload: {
          challenge: apiResponse.data.challenge,
          payload: apiResponse.data.payload || null,
        },
      })
    }
  }

  const resetPassword = async ({
    current_password,
    new_password,
    new_password_confirm,
  }) => {
    const res = await axios.post(`/user/resetPassword`, {
      current_password,
      new_password,
      new_password_confirm,
    })

    if (res.status !== 204) {
      throw new Error('Password reset failed')
    }

    await logout()
  }

  const resetTempPasswordChallenge = async ({
    current_password,
    new_password,
    new_password_confirm,
  }) => {
    resolveChallengeResponse(
      await axios.post(`/auth/resetTempPassword`, {
        current_password,
        new_password,
        new_password_confirm,
      })
    )
  }

  const setupMFA = async ({ mfaSecret, code }) => {
    resolveChallengeResponse(
      await axios.post(`/auth/mfaSetup`, {
        secret: mfaSecret,
        code,
      })
    )
  }

  const enterMFACode = async ({ code }) => {
    resolveChallengeResponse(
      await axios.post(`/auth/mfa`, {
        code: code?.toString(),
      })
    )
  }

  const initialize = () => {
    try {
      const userState = JSON.parse(
        localStorage.userState ?? JSON.stringify(initialState)
      )

      if (userState.challenge) {
        // sign out user if they refresh when there's a challenge
        throw new Error('Challenge detected')
      } else {
        let isAuthenticated = isUserAuthenticated(userState.tokenData)

        dispatch({
          type: 'INITIALIZE',
          payload: {
            ...userState,
            isAuthenticated,
          },
        })

        updateToken({
          token: userState?.token?.accessToken,
          token_expires: userState?.token?.accessTokenExpires,
          refresh_token: userState?.token?.refresh_token,
          refresh_expires: userState?.token?.refresh_expires,
        })
      }
    } catch (err) {
      storeState(initialState)
    }
  }

  const isUserAuthenticated = (tokenData) => {
    if (!tokenData) return false
    return isValidToken(tokenData) && state.challenge === ''
  }

  const setAuthentication = (isAuthenticated) => {
    dispatch({
      type: 'SET_AUTHENTICATION',
      payload: {
        isAuthenticated,
      },
    })
  }

  const refreshAuthToken = useCallback(async () => {
    if (
      state?.isInitialized &&
      state?.isAuthenticated &&
      !refreshingTokens &&
      state?.token?.accessToken &&
      !isValidToken(state?.token)
    ) {
      setRefreshingTokens(true)
    }
  }, [state, refreshingTokens, setRefreshingTokens])

  useEffect(() => {
    ;(async () => {
      if (refreshingTokens) {
        await refreshTokens()
        setRefreshingTokens(false)
      }
    })()
  }, [refreshTokens, refreshingTokens])

  useEffect(initialize, [])

  // store state in local storage whenever state changes in memory
  useEffect(() => {
    const asyncCallback = async () => {
      if (state.isInitialized) {
        let tokenData = state.token

        if (!isValidToken(state.token)) {
          tokenData = await refreshTokens()
        }

        const isAuthenticated = isUserAuthenticated(tokenData)
        if (isAuthenticated) {
          console.log(state.tokenInterceptorID)
          if (state.tokenInterceptorID === undefined) {
            const tokenInterceptorID = axios.interceptors.response.use(
              (response) => {
                return response
              },
              requestRefreshInterceptor
            )
            if (tokenInterceptorID > 0) {
              // cleanup on dead ones
              console.log(axios.interceptors.response)
              axios.interceptors.response.eject(tokenInterceptorID - 1)
            }
            dispatch({
              type: 'UPDATE_TOKEN_INTERCEPTOR',
              payload: {
                tokenInterceptorID: tokenInterceptorID,
              },
            })
          }
        } else if (state.tokenIncerceptorID !== undefined) {
          axios.interceptors.response.eject(state.tokenIncerceptorID)
          dispatch({
            type: 'UPDATE_TOKEN_INTERCEPTOR',
            payload: {
              tokenInterceptorID: null,
            },
          })
        }

        if (state.isAuthenticated !== isAuthenticated)
          setAuthentication(isAuthenticated)

        dispatch({ type: 'STORE_STATE' })
      }
    }

    asyncCallback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, requestRefreshInterceptor])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        resetPassword,
        resetTempPasswordChallenge,
        setupMFA,
        enterMFACode,
        refreshAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
