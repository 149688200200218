import React, { useState, useRef } from 'react'
import { Formik } from 'formik'
import Grid from '@mui/material/Grid'
import FieldGroup from 'components/common/FormRenderer/FieldGroup'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import resolveErrorText from 'helpers/resolveErrorText'

import SingleTitleLayout from 'layouts/SingleTitle'

import axios from 'utils/axios'

function DIYForm() {
  const formikRef = useRef(null)
  const [message, setMessage] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (values, { setErrors }) => {
    setIsSubmitting(true)
    try {
      if (!values.email) {
        throw new Error('Email is required')
      }
      if (!values.name) {
        throw new Error('Name is required')
      }
      // do a call to the backend first
      // this will throw errors if they occur,
      await axios.post('/diyintake/intake', {
        name: values.name,
        email: values.email,
      })

      window.location = `/diythanks?name=${values.name}&email=${values.email}`
    } catch (err) {
      setMessage(resolveErrorText(err))
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <SingleTitleLayout title="DIY Form">
      {message && <Alert severity="warning">{message}</Alert>}
      <Formik
        initialValues={{}}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validateOnChange={false}
        p={2}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FieldGroup
              fieldData={{
                field: 'name',
                display: 'Your Name',
                type: 'text',
                InputProps: {
                  disabled: isSubmitting,
                  required: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FieldGroup
              fieldData={{
                field: 'email',
                display: 'Your Email',
                type: 'email',
                InputProps: {
                  disabled: isSubmitting,
                  required: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={isSubmitting}
              onClick={() => {
                formikRef.current.submitForm()
              }}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Formik>
    </SingleTitleLayout>
  )
}

export default DIYForm
